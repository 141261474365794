<template>
    <div>
        <div class="price">
            <span>￥{{arrs.price}}</span>
        </div>
        <div class="card">
            <van-cell-group>
                <van-cell title="SIM卡号" :value="arrs.msisdn"/>
                <van-cell title="ICCID" :value="arrs.iccid"/>
                <van-cell title="流量套餐" :value="arrs.packName"/>
                <van-cell title="周期数" :value="arrs.cycle"/>
                <van-cell title="单价" :value="arrs.firstPrice"/>
                <van-cell title="有效期至" :value="arrs.date"/>
            </van-cell-group>
        </div>
        <div class="button">
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'Renew',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            // this.$api.addRenew({list:[this.arrs]}).then(res => {
            //     if(res.data.code == 0){
            //         localStorage.renewOrder = res.data.data
            //         localStorage.removeItem('renew');
            //     }
            // })
            localStorage.removeItem('renew');
            this.$router.push({name:'Home'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "支付订单");
        if(!localStorage.renew){
            this.$toast.fail("未发现订单");
            this.$router.push('/cycle')
        }else{
            
            this.arrs = JSON.parse(localStorage.renew)
            console.log(this.arrs)
        }
    }
}
</script>
<style lang="scss" scoped>
.price{
    span{
        color: #EB5E00;font-size: 60px;
    }
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
</style>
    
